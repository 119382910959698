import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import "./champion.css";
import championMapping from "./mapping";
import TopIcon from '../img/topIcon.svg';
import JugIcon from '../img/jugIcon.svg';
import MidIcon from '../img/midIcon.svg';
import AdcIcon from '../img/adcIcon.svg';
import SupIcon from '../img/supIcon.svg';
import Header from '../Components/header'

// 포지션 및 아이콘 설정
const positions = ["탑", "정글", "미드", "원딜", "서폿"];
const positionIcons = {
    top: { src: TopIcon },
    jungle: { src: JugIcon },
    mid: { src: MidIcon },
    adc: { src: AdcIcon },
    sup: { src: SupIcon },
};

function Champion() {
    const [championStats, setChampionStats] = useState([]);
    const [totalGames, setTotalGames] = useState(0);
    const [loading, setLoading] = useState(true);
    const [expandedChampion, setExpandedChampion] = useState(null);
    const [sortField, setSortField] = useState("games"); // 초기 정렬 필드
    const [sortOrder, setSortOrder] = useState("desc"); // 초기 정렬 순서
    const [playedChampionsRatio, setPlayedChampionsRatio] = useState(0); // 플레이된 챔피언 비율
    const [playedChampions, setPlayedCampions] = useState(0);
    const [totalChampions, setTotalChampions] = useState(0);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "record"));
                const records = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const stats = {};
                let totalPlays = 0;

                // 데이터를 챔피언별로 집계
                records.forEach((record) => {
                    const { champion, winOrLose, position, side, gameId } = record;
                    totalPlays++;

                    if (!stats[champion]) {
                        stats[champion] = { games: 0, wins: 0, losses: 0, details: [] };
                    }

                    stats[champion].games++;
                    if (winOrLose === "win") {
                        stats[champion].wins++;
                    } else {
                        stats[champion].losses++;
                    }

                    // 상대 라이너 찾기
                    const opponent = records.find(
                        (opponentRecord) =>
                            opponentRecord.gameId === gameId && // 같은 게임
                            opponentRecord.side !== side && // 상대 팀
                            opponentRecord.position === position // 같은 라인
                    );

                    stats[champion].details.push({
                        gameId,
                        position,
                        player: record.player,
                        opponent: opponent ? opponent.player : "Unknown",
                        opponentChampion: opponent ? opponent.champion : "Unknown", // 상대 챔피언 추가
                        winOrLose: record.winOrLose,
                        kill: parseInt(record.kill),
                        death: parseInt(record.death),
                        assist: parseInt(record.assist),
                        kda: (
                            (parseInt(record.kill) + parseInt(record.assist)) /
                            (parseInt(record.death) || 1)
                        ).toFixed(2), // KDA 계산
                    });
                });

                // 데이터를 정리
                const processedStats = Object.keys(stats).map((champion) => {
                    const { games, wins, losses, details } = stats[champion];
                    return {
                        champion,
                        games,
                        wins,
                        losses,
                        winRate: ((wins / games) * 100).toFixed(1),
                        pickRate: ((games / totalPlays) * 100).toFixed(2),
                        details: details.sort((a, b) => parseInt(b.gameId) - parseInt(a.gameId)), // 게임 ID 내림차순 정렬
                    };
                });

                setChampionStats(processedStats);
                setTotalGames(totalPlays);

                // 챔피언 매핑의 총 챔피언 수
                const totalChampions = Object.keys(championMapping).length;
                setTotalChampions(totalChampions)
                // 플레이된 챔피언 수
                const playedChampions = processedStats.length;
                setPlayedCampions(playedChampions)

                // 비율 계산 (플레이된 챔피언 수 / 총 챔피언 수)
                const ratio = (playedChampions / totalChampions) * 100;
                setPlayedChampionsRatio(ratio.toFixed(1));

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    // 정렬 처리
    const handleSort = (field) => {
        if (sortField === field) {
            // 같은 필드를 클릭하면 정렬 순서 변경
            setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
        } else {
            // 다른 필드를 클릭하면 필드 변경, 정렬 순서는 기본적으로 내림차순
            setSortField(field);
            setSortOrder("desc");
        }
    };

    const sortedStats = [...championStats].sort((a, b) => {
        const fieldA = sortField === "winRate" || sortField === "pickRate" ? parseFloat(a[sortField]) : a[sortField];
        const fieldB = sortField === "winRate" || sortField === "pickRate" ? parseFloat(b[sortField]) : b[sortField];
    
        if (sortOrder === "asc") {
            return fieldA > fieldB ? 1 : -1;
        } else {
            return fieldA < fieldB ? 1 : -1;
        }
    });
    

    const toggleDetails = (champion) => {
        setExpandedChampion((prev) => (prev === champion ? null : champion));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
        <Header></Header>
        <div className="champion-stats">
            <h2>챔피언 통계</h2>
            <div className="champion-stats-title">
            <p>게임 수 : {totalGames / 10}</p>
            <p>사용 챔피언 : {playedChampions} / {totalChampions} ({playedChampionsRatio}%)</p> {/* 챔피언 비율 표시 */}
            </div>
            <table>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("champion")}>
                            챔피언 {sortField === "champion" && (sortOrder === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => handleSort("games")}>
                            경기 {sortField === "games" && (sortOrder === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => handleSort("wins")}>
                            승 {sortField === "wins" && (sortOrder === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => handleSort("losses")}>
                            패 {sortField === "losses" && (sortOrder === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => handleSort("winRate")}>
                            승률{sortField === "winRate" && (sortOrder === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => handleSort("pickRate")}>
                            픽률{sortField === "pickRate" && (sortOrder === "asc" ? "▲" : "▼")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedStats.map((stat) => (
                        <React.Fragment key={stat.champion}>
                            <tr onClick={() => toggleDetails(stat.champion)}>
                                <td className="ChampionTd">
                                    <img
                                        src={`https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[stat.champion]}.png`}
                                        alt={stat.champion}
                                        style={{ width: "32px", height: "32px", marginRight: "8px" }}
                                    />
                                    {stat.champion}
                                </td>
                                <td>{stat.games}</td>
                                <td>{stat.wins}</td>
                                <td>{stat.losses}</td>
                                <td>{stat.winRate}%</td>
                                <td>{(stat.pickRate * 10).toFixed(1)}%</td>
                            </tr>
                            {expandedChampion === stat.champion && (
                                <tr className="details-row">
                                    <td colSpan="6">
                                        <div className="details-box">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>라인</th>
                                                        <th>vs</th>
                                                        <th>플레이어</th>
                                                        <th>승/패</th>
                                                        <th>K</th>
                                                        <th>D</th>
                                                        <th>A</th>
                                                        <th>KDA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stat.details.map((detail, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <img
                                                                    src={positionIcons[detail.position]?.src}
                                                                    alt={detail.position}
                                                                    style={{
                                                                        width: "32px",
                                                                        height: "32px",
                                                                        marginRight: "8px",
                                                                        backgroundColor:"rgb(37, 36, 36)"
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {detail.opponentChampion !== "Unknown" && (
                                                                    <img
                                                                        src={`https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[detail.opponentChampion]}.png`}
                                                                        alt={detail.opponentChampion}
                                                                        style={{
                                                                            width: "32px",
                                                                            height: "32px",
                                                                            marginRight: "8px",
                                                                        }}
                                                                    />
                                                                )}
                                                                {detail.opponent}
                                                            </td>
                                                            <td>{detail.player}</td>
                                                            <td
                                                                style={{
                                                                    color:
                                                                        detail.winOrLose === "win"
                                                                            ? "green"
                                                                            : "red",
                                                                }}
                                                            >
                                                                {detail.winOrLose === "win" ? "승" : "패"}
                                                            </td>
                                                            <td>{detail.kill}</td>
                                                            <td>{detail.death}</td>
                                                            <td>{detail.assist}</td>
                                                            <td>{detail.kda}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
        </>
    );
}

export default Champion;
