import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Firebase 설정 파일 import
import './winrate.css';
import Header from "./header";

function WinRate() {
    const [players, setPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [playerStats, setPlayerStats] = useState([]);  // 플레이어 통계
    const [loading, setLoading] = useState(true);
    const [sortField, setSortField] = useState("games"); // 기본 정렬 필드
    const [sortOrder, setSortOrder] = useState("desc"); // 기본 정렬 순서 (내림차순)

    // 플레이어 목록을 가져오는 useEffect
    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "record"));
                const records = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // 모든 플레이어 이름 추출
                const uniquePlayers = [...new Set(records.map(record => record.player))];
                setPlayers(uniquePlayers);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching players: ", error);
            }
        };

        fetchPlayers();
    }, []);

    // 팀 경기 통계 및 적 통계를 계산하는 함수
    const calculateStats = async (selected) => {
        setLoading(true);
        try {
            const querySnapshot = await getDocs(collection(db, "record"));
            const records = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            const playerStats = {}; // 각 플레이어의 통계를 저장하는 객체
    
            // 선택된 플레이어와 관련된 게임 기록들
            const selectedRecords = records.filter(record => record.player === selected);
    
            // 선택된 플레이어와 팀을 이룬 플레이어 및 적으로 만난 플레이어 통계를 계산
            selectedRecords.forEach(({ gameId, side, winOrLose }) => {
                // 동일한 게임에서 팀원을 찾는다.
                const teammates = records.filter(
                    record =>
                        record.gameId === gameId &&
                        record.side === side &&
                        record.player !== selected // 자기 자신 제외
                );
    
                // 팀원 통계
                teammates.forEach(teammate => {
                    const name = teammate.player;
                    if (!playerStats[name]) {
                        playerStats[name] = { teamGames: 0, teamWins: 0, enemyGames: 0, enemyWins: 0 };
                    }
    
                    // 팀 경기 통계
                    playerStats[name].teamGames++;
                    if (teammate.winOrLose === "win") {
                        playerStats[name].teamWins++;
                    }
                });
    
                // 적 팀원 통계
                const enemies = records.filter(
                    record =>
                        record.gameId === gameId &&
                        record.side !== side && // 적 팀
                        record.player !== selected // 자기 자신 제외
                );
    
                enemies.forEach(enemy => {
                    const enemyName = enemy.player;
                    if (!playerStats[enemyName]) {
                        playerStats[enemyName] = { teamGames: 0, teamWins: 0, enemyGames: 0, enemyWins: 0 };
                    }
    
                    // 적 팀 경기 통계
                    playerStats[enemyName].enemyGames++;
                    if (enemy.winOrLose === "win") {
                        playerStats[enemyName].enemyWins++;
                    }
                });
            });
    
            // 통계 계산 후 상태 업데이트
            const formattedStats = Object.entries(playerStats).map(([name, { teamGames, teamWins, enemyGames, enemyWins }]) => ({
                name,
                teamGames,
                teamWins,
                teamWinRate: teamGames > 0 ? ((teamWins / teamGames) * 100).toFixed(1) : "0.0",
                enemyGames,
                enemyWins,
                enemyWinRate: enemyGames > 0 ? ((enemyWins / enemyGames) * 100).toFixed(1) : "0.0",
            }));
    
            setPlayerStats(formattedStats);
            setLoading(false);
        } catch (error) {
            console.error("Error calculating stats: ", error);
        }
    };
    

    // 플레이어 선택 시 호출되는 함수
    const handlePlayerChange = (event) => {
        const player = event.target.value;
        setSelectedPlayer(player);
        calculateStats(player);
    };

    // 정렬 처리 함수
    const handleSort = (field) => {
        // 클릭한 필드가 기존에 정렬된 필드라면 정렬 순서 변경
        if (sortField === field) {
            setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            // 새로운 필드를 클릭했다면 해당 필드를 기준으로 정렬
            setSortField(field);
            setSortOrder("desc");
        }
    };

    // 통계 정렬
    const sortedPlayerStats = [...playerStats].sort((a, b) => {
        if (sortOrder === "asc") {
            return a[sortField] > b[sortField] ? 1 : -1;
        } else {
            if (sortField === "teamWinRate" || sortField === "enemyWinRate") {
                return parseFloat(b[sortField]) - parseFloat(a[sortField]);
            }
            return a[sortField] < b[sortField] ? 1 : -1;
        }
    });

    // 로딩 상태
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
        <Header></Header>
        <div className="winRateDiv">
            <h2>팀 승률 & 상대 승률</h2>
            <label htmlFor="player-select">플레이어 선택: </label>
            <select id="player-select" onChange={handlePlayerChange} value={selectedPlayer || ""}>
                <option value="" disabled>
                    플레이어를 선택하세요
                </option>
                {players.map(player => (
                    <option key={player} value={player}>
                        {player}
                    </option>
                ))}
            </select>

            {selectedPlayer && (
                <div>
                    <h3>{selectedPlayer} with / vs</h3>
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort("name")}>
                                    플레이어 {sortField === "name" && (sortOrder === "asc" ? "▲" : "▼")}
                                </th>
                                <th onClick={() => handleSort("teamGames")}>
                                    팀으로 함께한 경기 {sortField === "teamGames" && (sortOrder === "asc" ? "▲" : "▼")}
                                </th>
                                <th onClick={() => handleSort("teamWins")}>
                                    팀 승리 {sortField === "teamWins" && (sortOrder === "asc" ? "▲" : "▼")}
                                </th>
                                <th onClick={() => handleSort("teamWinRate")}>
                                    팀 승률 {sortField === "teamWinRate" && (sortOrder === "asc" ? "▲" : "▼")}
                                </th>
                                <th onClick={() => handleSort("enemyGames")}>
                                    적으로 만난 경기 {sortField === "enemyGames" && (sortOrder === "asc" ? "▲" : "▼")}
                                </th>
                                <th onClick={() => handleSort("enemyWins")}>
                                    적으로 만났을 때 승리 {sortField === "enemyWins" && (sortOrder === "asc" ? "▲" : "▼")}
                                </th>
                                <th onClick={() => handleSort("enemyWinRate")}>
                                    적으로 만났을 때 승률 {sortField === "enemyWinRate" && (sortOrder === "asc" ? "▲" : "▼")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedPlayerStats.map(({ name, teamGames, teamWins, teamWinRate, enemyGames, enemyWins, enemyWinRate }) => (
                                <tr key={name}>
                                    <td>{name}</td>
                                    <td>{teamGames}</td>
                                    <td>{teamWins}</td>
                                    <td className="team">{teamWinRate}%</td>
                                    <td>{enemyGames}</td>
                                    <td>{enemyGames-enemyWins}</td>
                                    <td className="enemy">{(100-enemyWinRate).toFixed(1)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
        </>
    );
}

export default WinRate;
