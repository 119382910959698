import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import './read.css'
import Header from './header';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import championMapping from "./mapping";

function App() {
    const [data, setData] = useState([]); // 초기값을 빈 배열로 설정
    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태 추가

    useEffect(() => {
        // Firestore에서 데이터 가져오기
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "record"));
                const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                // player 별로 챔피언의 k/d/a와 승패 정보를 누적
                const playerData = items.reduce((acc, item) => {
                    const { player, champion, winOrLose, kill, death, assist } = item;

                    if (!acc[player]) {
                        acc[player] = {
                            totalKills: 0,
                            totalDeaths: 0,
                            totalAssists: 0,
                            totalWins: 0,
                            totalLosses: 0,
                            totalGames: 0,
                            champions: {},
                        };
                    }

                    acc[player].totalKills += parseInt(kill || 0, 10);
                    acc[player].totalDeaths += parseInt(death || 0, 10);
                    acc[player].totalAssists += parseInt(assist || 0, 10);

                    acc[player].totalGames += 1;
                    if (winOrLose === "win") {
                        acc[player].totalWins += 1;
                    } else {
                        acc[player].totalLosses += 1;
                    }

                    if (!acc[player].champions[champion]) {
                        acc[player].champions[champion] = { win: 0, lose: 0, kills: 0, deaths: 0, assists: 0 };
                    }
                    if (winOrLose === "win") {
                        acc[player].champions[champion].win += 1;
                    } else {
                        acc[player].champions[champion].lose += 1;
                    }

                    acc[player].champions[champion].kills += parseInt(kill || 0, 10);
                    acc[player].champions[champion].deaths += parseInt(death || 0, 10);
                    acc[player].champions[champion].assists += parseInt(assist || 0, 10);

                    return acc;
                }, {});

                const playerChampionWinRate = Object.entries(playerData).map(([player, stats]) => {
                    const kda = {
                        kills: stats.totalKills,
                        deaths: stats.totalDeaths,
                        assists: stats.totalAssists,
                    };

                    const totalGames = stats.totalGames;
                    const winRate = totalGames > 0 ? (stats.totalWins / totalGames) * 100 : 0;

                    const totalKdaRatio = stats.totalDeaths > 0
                        ? ((stats.totalKills + stats.totalAssists) / stats.totalDeaths).toFixed(2)
                        : "Perfect";

                    const championWinRates = Object.entries(stats.champions).map(([champion, record]) => {
                        const championTotalGames = record.win + record.lose;
                        const championWinRate = championTotalGames > 0 ? (record.win / championTotalGames) * 100 : 0;

                        const championKDA = {
                            kills: record.kills || 0,
                            deaths: record.deaths || 0,
                            assists: record.assists || 0,
                        };

                        const kdaRatio = record.deaths > 0
                            ? ((record.kills + record.assists) / record.deaths).toFixed(2)
                            : "Perfect";

                        return {
                            champion,
                            winRate: championWinRate.toFixed(1),
                            win: record.win,
                            lose: record.lose,
                            kda: championKDA,
                            kdaRatio,
                            imageUrl: championMapping[champion]
                                ? `https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[champion]}.png`
                                : 'https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/Unknown.png',
                            championInfo: championMapping[champion]
                                ? `https://www.op.gg/champions/${championMapping[champion]}/build`
                                : 'https://www.op.gg/champions',
                        };
                    });

                    championWinRates.sort((a, b) => {
                        const aTotalGames = a.win + a.lose;
                        const bTotalGames = b.win + b.lose;

                        if (bTotalGames === aTotalGames) {
                            return b.win - a.win;
                        }

                        return bTotalGames - aTotalGames;
                    });

                    return {
                        player,
                        totalWins: stats.totalWins,
                        totalLosses: stats.totalLosses,
                        kda,
                        winRate: winRate.toFixed(2),
                        totalKdaRatio,
                        totalGames,
                        championWinRates,
                    };
                });

                // 플레이어 데이터를 판수 내림차순으로 정렬
                playerChampionWinRate.sort((a, b) => b.totalGames - a.totalGames);

                setData(playerChampionWinRate);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    // 검색어에 맞는 플레이어 데이터 필터링
    const filteredData = searchTerm.trim() === "" 
    ? data  // 검색어가 비어 있으면 모든 데이터 표시
    : data.filter(playerData => {
        const players = searchTerm.split(',').map(player => player.trim().toLowerCase());
        return players.includes(playerData.player.toLowerCase());
    });
    return (
        <>
            <Header />
            {/* 검색창 */}
            <div className="playerDataSearchBox">
                <input
                    type="text"
                    placeholder="플레이어 이름 검색 (콤마로 구분)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            <div className="playerDataParents">
            {/* 데이터가 존재하는지 확인 후, map() 실행 */}
            {filteredData.length > 0 ? (
                filteredData.map((playerData) => (
                    <div key={playerData.player} id={`player-${playerData.player}`} className="player">
                        <div className="player-info">
                            <strong>{playerData.player}</strong>

                            {/* 승패수 표시 */}
                            <div className="player-stats">
                                <div className="win-loss">
                                    {playerData.totalWins}승 {playerData.totalLosses}패 ({playerData.winRate}%)
                                </div>
                                <div className="kda">
                                    K/D/A {playerData.kda.kills} / {playerData.kda.deaths} / {playerData.kda.assists} ({playerData.totalKdaRatio})
                                </div>
                            </div>
                        </div>

                        {/* 각 챔피언별 승률 및 KDA 표시 */}
                        <div className="champion-win-rates">
                            {playerData.championWinRates && playerData.championWinRates.length > 0 ? (
                                playerData.championWinRates.map((championData) => (
                                    <div key={championData.champion} className="champion">
                                        <div className="cropping">
                                            <img src={championData.imageUrl} alt={championData.champion} className="championImg" />
                                        </div>
                                        <div className="champion-name"><Link to={championData.championInfo} target='_blank'>{championData.champion}</Link></div>
                                        <div className="champion-record">
                                            {championData.win}승 {championData.lose}패 ({championData.winRate}%)
                                        </div>
                                        <div className="champion-kda">
                                            {championData.kda.kills} / {championData.kda.deaths} / {championData.kda.assists} ({championData.kdaRatio})
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No champion data available.</p>  /* 챔피언 데이터가 없을 경우 */
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <p>Loading data...</p>  /* 데이터가 없을 때 로딩 메시지 출력 */
            )}
        </div>
        </>
    );
}

export default App;
