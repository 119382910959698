import './App.css'
import Add from './Components/add.js';
import Read from './Components/read.js';
import Team from './Components/team.js';
import Ranking from './Components/ranking.js';
import History from './Components/history.js';
import { Routes, Route, BrowserRouter, useParams } from "react-router-dom";
import Champion from './Components/champion.js';
import WinRate from './Components/winrate.js';


function App() {

  return (
    <div className='container'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Read />} />
          <Route path="/add" element={<Add />} />
          <Route path="/team" element={<Team />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/history" element={<History />} />
          <Route path="/champion" element={<Champion />} />
          <Route path="/winrate" element={<WinRate />} />
        </Routes>
      </BrowserRouter>
    </div>
    
  )
};

export default App
