// src/App.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './team.css';
import Header from './header';
import TopIcon from '../img/topIcon.svg';
import JugIcon from '../img/jugIcon.svg';
import MidIcon from '../img/midIcon.svg';
import AdcIcon from '../img/adcIcon.svg';
import SupIcon from '../img/supIcon.svg';

const positions = ["탑", "정글", "미드", "원딜", "서폿"];

const positionIcons = {
    탑: {src : TopIcon},
    정글: {src : JugIcon},
    미드: {src : MidIcon},
    원딜: {src : AdcIcon},
    서폿: {src : SupIcon},
};

const fetchUniquePlayers = async () => {
  const querySnapshot = await getDocs(collection(db, 'record'));
  const playersData = querySnapshot.docs.map((doc) => doc.data().player);
  return Array.from(new Set(playersData));
};

const Player = ({ player, onRemove }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'player',
    item: { player },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        onRemove(player);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className="player-card"
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {player}
    </div>
  );
};

const PositionDropArea = ({ position, players, onDropPlayer, onRemovePlayer }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'player',
    drop: (item) => onDropPlayer(position, item.player),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleRemovePlayer = (player) => {
    onRemovePlayer(position, player);
  };

  return (
    <div
      ref={drop}
      className="position-drop-area"
      style={{
        backgroundColor: isOver ? '#d7ffd7' : '#f9f9f9',
      }}
    >
      <h4>{position}</h4>
      {players[position].map((player) => (
        <div key={player} className="assigned-player">
          {player}
          <button
            onClick={() => handleRemovePlayer(player)}
            className="remove-btn"
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
};

function App() {
  const [players, setPlayers] = useState([]);
  const [selectedPlayersByPosition, setSelectedPlayersByPosition] = useState({
    탑: [],
    정글: [],
    미드: [],
    원딜: [],
    서폿: [],
  });
  const [teamAssignment, setTeamAssignment] = useState({ teamA: {}, teamB: {} });
  const [newPlayer, setNewPlayer] = useState('');

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const uniquePlayers = await fetchUniquePlayers();
        setPlayers(uniquePlayers);
      } catch (error) {
        console.error("Error fetching players: ", error);
      }
    };
    fetchPlayers();
  }, []);

  const handleDropPlayer = (position, player) => {
  setSelectedPlayersByPosition((prev) => {
    const playersInPosition = prev[position];
    // 이미 해당 포지션에 플레이어가 두 명 있으면, 새로운 플레이어를 추가하지 않음
    if (playersInPosition.length >= 2) {
      alert(`${position} 포지션은 최대 2명까지 선택할 수 있습니다.`);
      return prev;  // 상태를 변경하지 않음
    }
    // 해당 포지션에 플레이어가 없거나 1명만 있으면 추가
    return {
      ...prev,
      [position]: [...playersInPosition, player], // 새로운 플레이어 추가
    };
  });
};


  const removePlayerFromList = (player) => {
    setPlayers((prevPlayers) => prevPlayers.filter((p) => p !== player));
  };

  const handleRemovePlayer = (position, player) => {
    setSelectedPlayersByPosition((prev) => {
      const updatedPositionPlayers = prev[position].filter((p) => p !== player);
      return {
        ...prev,
        [position]: updatedPositionPlayers,
      };
    });

    setPlayers((prevPlayers) => [...prevPlayers, player]);
  };

  const assignTeams = () => {
    const teamA = {};
    const teamB = {};
    for (const position of positions) {
      const playersForPosition = selectedPlayersByPosition[position];
      if (playersForPosition.length < 2) {
        alert(`모든 포지션에 2명의 플레이어가 필요합니다.`);
        return;
      }
      const shuffledPlayers = [...playersForPosition].sort(() => Math.random() - 0.5);
      teamA[position] = shuffledPlayers[0];
      teamB[position] = shuffledPlayers[1];
    }
    setTeamAssignment({ teamA, teamB });
  };

  const handleAddPlayer = () => {
    if (newPlayer.trim() === '') return;
    setPlayers((prevPlayers) => [...prevPlayers, newPlayer]);
    setNewPlayer('');
  };

  const generateTeamMessage = () => {
    const teamAMembers = Object.values(teamAssignment.teamA).join(' ');
    const teamBMembers = Object.values(teamAssignment.teamB).join(' ');
    return `1팀: ${teamAMembers}\n2팀: ${teamBMembers}`;
  };

  const handleCopyTeamMessage = () => {
    const teamMessage = generateTeamMessage();
    navigator.clipboard.writeText(teamMessage)
      .then(() => {
        alert('팀 구성이 클립보드에 복사되었습니다!');
      })
      .catch((error) => {
        console.error('복사 실패:', error);
      });
  };

  const handleResetPositions = () => {
    // 선택된 포지션 초기화 및 플레이어 목록 초기화
    setSelectedPlayersByPosition({
      탑: [],
      정글: [],
      미드: [],
      원딜: [],
      서폿: []
    });
    setPlayers((prevPlayers) => [...prevPlayers, ...Object.values(selectedPlayersByPosition).flat()]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="teamContainer">
      <Header></Header>
        <h1>드래그하여 포지션에 플레이어 추가</h1>

        <div className="player-list">
          {players.map((player) => (
            <Player key={player} player={player} onRemove={removePlayerFromList} />
          ))}

<div className="add-player-container">
          <input
            type="text"
            value={newPlayer}
            onChange={(e) => setNewPlayer(e.target.value)}
            placeholder="이름 추가하기"
            className="add-player-input"
          />
          <button onClick={handleAddPlayer} className="add-player-btn">+</button>
        </div>
        </div>

        

        <div className="position-container">
          {positions.map((position) => (
            <PositionDropArea
              key={position}
              position={position}
              players={selectedPlayersByPosition}
              onDropPlayer={handleDropPlayer}
              onRemovePlayer={handleRemovePlayer}
            />
          ))}

          
        </div>

        

        <button onClick={assignTeams} className="assign-btn">
          팀 배정하기
        </button>

        <button onClick={handleResetPositions} className="reset-btn">
          배정 초기화
        </button>

        {teamAssignment.teamA && (
          <div className="team-result">
            
            <table className="team-table">
              <thead>
                <tr>
                  <th className="blue">Blue</th>
                  <th className="middle"><button onClick={handleCopyTeamMessage} className="copy-btn">팀 구성 복사하기</button></th>
                  <th className="red">Red</th>
                </tr>
              </thead>
              <tbody>
                {positions.map((position) => (
                  <tr key={position}>
                    <td>{teamAssignment.teamA[position]}</td>
                    <td className="position">
                      <img
                        src={positionIcons[position].src}
                        alt={`${position}`}
                        style={{ width: '32px', height: '32px' }}
                      />
                    </td>
                    <td>{teamAssignment.teamB[position]}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            
          </div>
        )}
      </div>
    </DndProvider>
  );
}

export default App;
