import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import "./history.css";
import Header from "./header";
import TopIcon from '../img/topIcon.svg';
import JugIcon from '../img/jugIcon.svg';
import MidIcon from '../img/midIcon.svg';
import AdcIcon from '../img/adcIcon.svg';
import SupIcon from '../img/supIcon.svg';
import championMapping from "./mapping";

function History() {
    const [groupedData, setGroupedData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // 포지션 순서를 정의
    const positionOrder = ["top", "jungle", "mid", "adc", "sup"];

    const positionIcons = {
        top: {src : TopIcon},
        jungle: {src : JugIcon},
        mid: {src : MidIcon},
        adc: {src : AdcIcon},
        sup: {src : SupIcon},
    };

      useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "record"));
                const records = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                console.log("Fetched records:", records); // Fetch된 데이터 로그

                const grouped = records.reduce((acc, record) => {
                    const { gameId, side, winOrLose, kill } = record;

                    // 유효성 검사
                    if (!gameId || (side !== "blue" && side !== "red")) {
                        console.warn("Invalid record:", record);
                        return acc; // 잘못된 데이터 무시
                    }

                    // 초기화
                    if (!acc[gameId]) {
                        acc[gameId] = { blue: [], red: [], winOrLose: {}, totalKills: { blue: 0, red: 0 } };
                    }

                    // 데이터 추가
                    acc[gameId][side].push(record);
                    acc[gameId].winOrLose[side] = winOrLose; // 게임 승패 기록

                    acc[gameId].totalKills[side] += parseInt(kill, 10) || 0; // kill 값이 없거나 변환 실패 시 0으로 처리

                    return acc;
                }, {});

                // 포지션 기준으로 정렬
                Object.keys(grouped).forEach((gameId) => {
                    ["blue", "red"].forEach((side) => {
                        grouped[gameId][side].sort(
                            (a, b) => positionOrder.indexOf(a.position) - positionOrder.indexOf(b.position)
                        );
                    });
                });

                console.log("Grouped data (sorted):", grouped); // 정렬된 데이터 로그
                setGroupedData(grouped);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data: ", error);
                setError("데이터를 가져오는 중 문제가 발생했습니다.");
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
        <Header></Header>
        <div className="history">
            
        {Object.keys(groupedData)
                .sort((a, b) => parseInt(b) - parseInt(a)) // gameId를 숫자 기준으로 내림차순 정렬
                .map((gameId) => (
                <div key={gameId} className="game-block">
                    <p className="gameid">게임ID : {gameId}</p>
                    <div className="game-block-child">
                    <div className="blueteam">
                        <p 
                        style={{
                        backgroundColor: groupedData[gameId].winOrLose.blue === "win" ? "#007bff" : "#ff3300",
                        color: "white",
                        padding: "5px"
                    }}>{groupedData[gameId].winOrLose.blue === "win" ? "승리" : "패배"}<span className="blueTeamKill">{groupedData[gameId].totalKills.blue}</span></p>
                        <table>
                            <thead>
                                <tr>
                                <th>CS</th>
                                <th>K</th>
                                <th>D</th>
                                <th>A</th>
                                <th className="sideTh">Blue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupedData[gameId].blue.map((player) => (
                                    <tr key={player.id}>
                                        <td>{player.cs}</td>
                                        <td>{player.kill}</td>
                                        <td>{player.death}</td>
                                        <td>{player.assist}</td>
                                        <td className="blueimgtd"><p>{player.player}</p><div className="cropping"><img
                                        src={
                                            championMapping[player.champion]
                                            ? `https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[player.champion]}.png`
                                            : 'https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/Unknown.png'
                                        }
                                        alt={player.champion}
                                        className="championImg"
                                        /></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    
                    </div>
                    <div className="redteam">
                        <p 
                            style={{
                            backgroundColor: groupedData[gameId].winOrLose.red === "win" ? "#007bff" : "#ff3300",
                            color: "white",
                            padding: "5px",
                        }}><span className="redTeamKill">{groupedData[gameId].totalKills.red}</span> {groupedData[gameId].winOrLose.red === "win" ? "승리" : "패배"} </p>
                        <table>
                            <thead>
                                <tr>
                                    <th className="sideTh">Red</th>
                                    <th>K</th>
                                    <th>D</th>
                                    <th>A</th>
                                    <th>CS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupedData[gameId].red.map((player) => (
                                    <tr key={player.id}>
                                        <td className="redimgtd"><div className="cropping"><img
                                        src={
                                            championMapping[player.champion]
                                            ? `https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[player.champion]}.png`
                                            : 'https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/Unknown.png'
                                        }
                                        alt={player.champion}
                                        className="championImg"
                                        /></div>
                                        <p>{player.player}</p></td>
                                        <td>{player.kill}</td>
                                        <td>{player.death}</td>
                                        <td>{player.assist}</td>
                                        <td>{player.cs}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
            ))}
        </div>
        </>
    );
}

export default History;