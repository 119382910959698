import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";

function AddDataComponent() {
    const [gameId, setGameId] = useState("");
    const [winningTeam, setWinningTeam] = useState(""); // 승리팀
    const [blueTeam, setBlueTeam] = useState(
        Array(5).fill({ player: "", position: "", champion: "", kill: "", death: "", assist: "", cs: "" })
    );
    const [redTeam, setRedTeam] = useState(
        Array(5).fill({ player: "", position: "", champion: "", kill: "", death: "", assist: "", cs: "" })
    );

    const handleChangeBlueTeam = (index, field, value) => {
        const newBlueTeam = [...blueTeam]; // 기존 배열을 복사
        newBlueTeam[index] = { ...newBlueTeam[index], [field]: value }; // 해당 인덱스의 항목만 업데이트
        setBlueTeam(newBlueTeam); // 상태 업데이트
    };

    const handleChangeRedTeam = (index, field, value) => {
        const newRedTeam = [...redTeam]; // 기존 배열을 복사
        newRedTeam[index] = { ...newRedTeam[index], [field]: value }; // 해당 인덱스의 항목만 업데이트
        setRedTeam(newRedTeam); // 상태 업데이트
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // 블루팀과 레드팀 데이터를 한 번에 기록하기
            for (let i = 0; i < 5; i++) {
                // 블루팀
                await addDoc(collection(db, "record"), {
                    gameId: gameId,
                    player: blueTeam[i].player,
                    side: "blue",
                    position: blueTeam[i].position,
                    winOrLose: winningTeam === "blue" ? "win" : "lose",
                    champion: blueTeam[i].champion,
                    kill: blueTeam[i].kill,
                    death: blueTeam[i].death,
                    assist: blueTeam[i].assist,
                    cs: blueTeam[i].cs,
                });

                // 레드팀
                await addDoc(collection(db, "record"), {
                    gameId: gameId,
                    player: redTeam[i].player,
                    side: "red",
                    position: redTeam[i].position,
                    winOrLose: winningTeam === "red" ? "win" : "lose",
                    champion: redTeam[i].champion,
                    kill: redTeam[i].kill,
                    death: redTeam[i].death,
                    assist: redTeam[i].assist,
                    cs: redTeam[i].cs,
                });
            }

            alert("데이터가 성공적으로 추가되었습니다!");
            // 초기화
            setGameId("");
            setWinningTeam("");
            setBlueTeam(Array(5).fill({ player: "", position: "", champion: "", kill: "", death: "", assist: "", cs: "" }));
            setRedTeam(Array(5).fill({ player: "", position: "", champion: "", kill: "", death: "", assist: "", cs: "" }));
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                게임 ID: <input type="text" value={gameId} onChange={(e) => setGameId(e.target.value)} placeholder="게임 ID" />
            </div>
            <div>
                승리 팀:
                <select value={winningTeam} onChange={(e) => setWinningTeam(e.target.value)}>
                    <option value="">선택</option>
                    <option value="blue">블루팀</option>
                    <option value="red">레드팀</option>
                </select>
            </div>

            {/* 블루팀 */}
            <h3>블루팀</h3>
            {blueTeam.map((player, index) => (
                <div key={`blue-${index}`}>
                    <h4>플레이어 {index + 1}</h4>
                    <input
                        type="text"
                        value={player.player}
                        onChange={(e) => handleChangeBlueTeam(index, "player", e.target.value)}
                        placeholder="플레이어 이름"
                    />
                    <select value={player.position} onChange={(e) => handleChangeBlueTeam(index, "position", e.target.value)}>
                        <option value="">포지션</option>
                        <option value="top">탑</option>
                        <option value="jungle">정글</option>
                        <option value="mid">미드</option>
                        <option value="adc">원딜</option>
                        <option value="sup">서포터</option>
                    </select>
                    <input
                        type="text"
                        value={player.champion}
                        onChange={(e) => handleChangeBlueTeam(index, "champion", e.target.value)}
                        placeholder="챔피언"
                    />
                    <input
                        type="text"
                        value={player.kill}
                        onChange={(e) => handleChangeBlueTeam(index, "kill", e.target.value)}
                        placeholder="킬"
                    />
                    <input
                        type="text"
                        value={player.death}
                        onChange={(e) => handleChangeBlueTeam(index, "death", e.target.value)}
                        placeholder="데스"
                    />
                    <input
                        type="text"
                        value={player.assist}
                        onChange={(e) => handleChangeBlueTeam(index, "assist", e.target.value)}
                        placeholder="어시스트"
                    />
                    <input
                        type="text"
                        value={player.cs}
                        onChange={(e) => handleChangeBlueTeam(index, "cs", e.target.value)}
                        placeholder="CS"
                    />
                </div>
            ))}

            {/* 레드팀 */}
            <h3>레드팀</h3>
            {redTeam.map((player, index) => (
                <div key={`red-${index}`}>
                    <h4>플레이어 {index + 1}</h4>
                    <input
                        type="text"
                        value={player.player}
                        onChange={(e) => handleChangeRedTeam(index, "player", e.target.value)}
                        placeholder="플레이어 이름"
                    />
                    <select value={player.position} onChange={(e) => handleChangeRedTeam(index, "position", e.target.value)}>
                        <option value="">포지션</option>
                        <option value="top">탑</option>
                        <option value="jungle">정글</option>
                        <option value="mid">미드</option>
                        <option value="adc">원딜</option>
                        <option value="sup">서포터</option>
                    </select>
                    <input
                        type="text"
                        value={player.champion}
                        onChange={(e) => handleChangeRedTeam(index, "champion", e.target.value)}
                        placeholder="챔피언"
                    />
                    <input
                        type="text"
                        value={player.kill}
                        onChange={(e) => handleChangeRedTeam(index, "kill", e.target.value)}
                        placeholder="킬"
                    />
                    <input
                        type="text"
                        value={player.death}
                        onChange={(e) => handleChangeRedTeam(index, "death", e.target.value)}
                        placeholder="데스"
                    />
                    <input
                        type="text"
                        value={player.assist}
                        onChange={(e) => handleChangeRedTeam(index, "assist", e.target.value)}
                        placeholder="어시스트"
                    />
                    <input
                        type="text"
                        value={player.cs}
                        onChange={(e) => handleChangeRedTeam(index, "cs", e.target.value)}
                        placeholder="CS"
                    />
                </div>
            ))}

            <button type="submit">추가하기</button>
        </form>
    );
}

export default AddDataComponent;
